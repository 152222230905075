import React from "react";
import { Helmet } from "react-helmet";

const suppliers = [
  {
    name: "Armstrong",
    logo: "/images/Suppliers/Armstrong-Flooring.png",
    url: "https://www.armstrongflooring.com/residential/en-us",
  },
  {
    name: "Cascade",
    logo: "/images/Suppliers/Cascade.png",
    url: "https://www.cascadeluxuryvinyl.com/product-category/luxury-vinyl-flooring/",
  },
  {
    name: "Centura tile",
    logo: "/images/Suppliers/Centura-tile.png",
    url: "https://www.centura.ca/en/products/categories/vinyl-rubber-37",
  },
  {
    name: "Evoke",
    logo: "/images/Suppliers/evoke-logo.png",
    url: "https://evokeflooring.com/us",
  },
  {
    name: "Goodfellow",
    logo: "/images/Suppliers/goodfellow-logo.png",
    url: "https://www.goodfellowinc.com",
  },
  {
    name: "Harbinger",
    logo: "/images/Suppliers/harbinger-logo.png",
    url: "https://www.harbingerfloors.com",
  },
  {
    name: "Kennedy",
    logo: "/images/Suppliers/Kennedy.png",
    url: "https://kennedyfloorings.com/products/luxuryvinyl/",
  },
  {
    name: "Mannington",
    logo: "/images/Suppliers/Mannington.png",
    url: "https://www.mannington.com/residential/vinylsheet/vinylsheet",
  },
  {
    name: "Primco",
    logo: "/images/Suppliers/Primco-PNG.png",
    url: "https://www.primco.ca/residential/luxury-vinyl/",
  },
  {
    name: "Stevensomni",
    logo: "/images/Suppliers/stevensomni-logo.png",
    url: "https://www.stevensomni.com",
  },
  // Add more suppliers as needed
];

const services = [
  {
    name: "Engineered Vinyl Planks & Tiles",
    description: "Our engineered vinyl planks and tiles are a testament to modern flooring technology. Combining the elegance of natural wood and stone with the practicality of vinyl, these floors are built to withstand the rigors of daily use. The easy-to-install floating floor system simplifies renovations, allowing for a hassle-free update to any room. Perfect for busy households, these floors are resistant to scratches, spills, and stains, making them a durable option for high-traffic areas.",
    image: "images/Services/EVP.webp"
  },
  {
    name: "Luxury Vinyl Planks & Tiles",
    description: "Our glue-down vinyl planks and tiles are a robust and stylish flooring solution. Ideal for both residential and commercial spaces, they offer enhanced stability and longevity. The design possibilities are endless, with a range of patterns and textures mimicking natural wood and stone. They are engineered to endure heavy foot traffic, making them an excellent choice for bustling areas. Additionally, their moisture-resistant properties make them suitable for kitchens, bathrooms, and basements.",
    image: "images/Services/LVP.webp"
  },
  {
    name: "Carpet Tiles",
    description: "Our glue-down vinyl planks and tiles are a robust and stylish flooring solution. Ideal for both residential and commercial spaces, they offer enhanced stability and longevity. The design possibilities are endless, with a range of patterns and textures mimicking natural wood and stone. They are engineered to endure heavy foot traffic, making them an excellent choice for bustling areas. Additionally, their moisture-resistant properties make them suitable for kitchens, bathrooms, and basements.",
    image: "images/Services/Carpet.webp"
  },
  {
    name: "VCT (Vinyl Composition Tile)",
    description: "Vinyl Composition Tile (VCT) is the choice for spaces that demand both style and substance. Renowned for its durability, VCT is perfect for high-traffic commercial environments like schools, hospitals, and retail spaces. These tiles offer a timeless look that can be customized through an array of patterns and colors. They are designed to be low-maintenance, requiring simple cleaning routines. Our full-service approach includes meticulous installation and finishing, ensuring your VCT flooring not only looks great but also performs exceptionally for years to come.",
    image: "images/Services/VCT.webp"
  },
];

const ServicesPage = () => {
  return (
    <div className="container mx-auto px-4">
      <Helmet>
        <title>Fusion Floor Finishings Edmonton - Professional Flooring Services</title>
        <meta name="description" content="Discover a range of professional flooring services by Fusion Floor Finishings in Edmonton. Specializing in high-quality vinyl, carpet, and VCT flooring installations." />
        <meta property="og:title" content="Fusion Floor Finishings Edmonton - Diverse Flooring Solutions" />
        <meta property="og:description" content="Explore top-notch flooring solutions in Edmonton with Fusion Floor Finishings. From luxury vinyl to practical carpet tiles, we offer a variety of installation services." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/Logo.jpeg" />
        <meta property="og:url" content="http://fusionfloorfinishings.ca/services" />
        <meta name="geo.region" content="CA-AB" />
        <meta name="geo.placename" content="Edmonton" />
        <link rel='canonical' href='http://fusionfloorfinishings.ca/services' />
      </Helmet>

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-96"
        style={{ backgroundImage: "url('/images/ServicesBanner.webp')" }}
      >
        <div className="flex flex-col justify-center items-center h-full bg-black bg-opacity-60 text-white">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
            Our Services!
          </h1>
          <p className="text-xl text-center mb-6">
            Explore our exclusive range of flooring solutions.
          </p>
        </div>
      </section>

        {/* Services Section */}
      <section className="my-8">
        <h2 className="text-2xl font-bold text-center mb-6">What We Offer</h2>
        {services.map((service, index) => (
          <div key={index} className={`flex flex-col md:flex-row mb-8 ${index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"}`}>
            <img className="md:w-1/2 object-cover rounded-lg" src={service.image} alt={service.name} />
            <div className="md:w-1/2 flex items-center bg-gray-100 p-4">
              <div>
                <h3 className="text-xl font-bold text-gray-900">{service.name}</h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* Suppliers Section */}
      <section className="suppliers my-8">
        <h2 className="text-2xl font-bold text-center mb-6">Our Suppliers</h2>
        <div className="flex justify-center items-center gap-6 flex-wrap">
          {suppliers.map((supplier) => (
            <a key={supplier.name} href={supplier.url} target="_blank" rel="noopener noreferrer" className="hover:opacity-80">
              <img src={supplier.logo} alt={`${supplier.name} logo`} className="h-24 object-contain" />
            </a>
          ))}
        </div>
        <h3 className="text-2xl font-bold text-center mb-6">
          Plus many more, 
          <a 
            href="/contact" 
            className="text-blue-600 hover:text-blue-900 px-2 py-1 rounded-md">
            Contact us
          </a> 
          for more info!
        </h3>
      </section>
    </div>
  );
};

export default ServicesPage;
