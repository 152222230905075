import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";

// Validation Schema
const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string(),
  brandProduct: Yup.string().required("Brand/Product is required"), 
  existingFloorType: Yup.string().required("Existing floor type is required"),
  furnitureMovement: Yup.string().required("This field is required"),
  baseboards: Yup.string().required("This field is required"),
  newFloorType: Yup.string().required("New floor type is required"),
  roomSquareFootage: Yup.number().required("Room square footage is required"),
  additionalDetails: Yup.string(),
});

const ContactPage = () => {
  const formRef = useRef(null);
  const [submitMessage, setSubmitMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const sendEmail = async (values, actions) => {
    try {
      const result = await emailjs.sendForm(
        "service_tegk5cn",
        "template_3p4iwvt",
        formRef.current,
        "SVQg_54EWN57X5G6J"
      );
      console.log(result.text);
      actions.setSubmitting(false);
      actions.resetForm();
      setSubmitMessage("Your message has been sent successfully!");
      setIsError(false);
    } catch (error) {
      console.error(error);
      actions.setSubmitting(false);
      setSubmitMessage(
        "An error occurred while sending your message. Please try again."
      );
      setIsError(true);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <Helmet>
        <title>Contact Fusion Floor Finishings Edmonton - Get Your Free Quote</title>
        <meta name="description" content="Contact Fusion Floor Finishings in Edmonton for a free quote on your flooring project. Explore top-quality vinyl flooring solutions with expert installation." />
        <meta property="og:title" content="Contact Fusion Floor Finishings Edmonton - Expert Flooring Solutions" />
        <meta property="og:description" content="Reach out to Fusion Floor Finishings in Edmonton for your next flooring project. Quality and luxury vinyl flooring with professional service." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/Logo.jpeg" />
        <meta property="og:url" content="http://fusionfloorfinishings.ca/contact" />
        <meta name="geo.region" content="CA-AB" />
        <meta name="geo.placename" content="Edmonton" />
        <link rel='canonical' href='http://fusionfloorfinishings.ca/contact' />
      </Helmet>

      <section className="contact mt-8 py-8">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold">Contact Us For A Free Quote</h1>
          <p className="mt-2 text-sm text-gray-700">
            Fill out the form or send us an email for more information!
          </p>
        </div>

        {/* New Section for Email and Facebook */}
      <section className="my-8 text-center">
        <div className="flex justify-center items-center gap-6">
          {/* Email */}
          <a href="mailto:info@fusionfloorfinishings.ca" className="flex flex-col items-center">
            <i className="fas fa-envelope text-3xl text-indigo-600"></i>
            <span className="mt-2 text-sm">fusionfloorfinishings@gmail.com</span>
          </a>
          {/* Facebook */}
          <a href="https://www.facebook.com/profile.php?id=61554940944642" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
            <i className="fab fa-facebook text-3xl text-indigo-600"></i>
            <span className="mt-2 text-sm">Our Facebook Page</span>
          </a>
        </div>
      </section>

        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            existingFloorType: "",
            furnitureMovement: "",
            baseboards: "",
            newFloorType: "",
            brandProduct: "",
            roomSquareFootage: "",
            additionalDetails: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={sendEmail}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-12" ref={formRef}>
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Quote Request Form
                </h2>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {/* Name Field */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Enter your name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Phone Field */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="phone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Phone (Optional)
                    </label>
                    <Field
                      id="phone"
                      name="phone"
                      type="tel"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Enter your phone number"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Email Field */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Enter your email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Existing Floor Type */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="existingFloorType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Existing Floor Type
                    </label>
                    <Field
                      id="existingFloorType"
                      name="existingFloorType"
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Type of existing floor"
                    />
                    <ErrorMessage
                      name="existingFloorType"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Furniture Movement */}
                  <div className="sm:col-span-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Furniture Movement Required?
                    </label>
                    <div className="mt-2 flex items-center">
                      <Field
                        type="radio"
                        name="furnitureMovement"
                        id="furnitureYes"
                        value="yes"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                      />
                      <label
                        htmlFor="furnitureYes"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Yes
                      </label>
                      <Field
                        type="radio"
                        name="furnitureMovement"
                        id="furnitureNo"
                        value="no"
                        className="ml-6 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                      />
                      <label
                        htmlFor="furnitureNo"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="furnitureMovement"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Existing Baseboards */}
                  <div className="sm:col-span-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      New or Existing Baseboards
                    </label>
                    <div className="mt-2 flex items-center">
                      <Field
                        type="radio"
                        name="baseboards"
                        id="baseboardsNew"
                        value="new"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                      />
                      <label
                        htmlFor="baseboardsNew"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        New Baseboards
                      </label>
                      <Field
                        type="radio"
                        name="baseboards"
                        id="baseboardsExisting"
                        value="existing"
                        className="ml-6 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                      />
                      <label
                        htmlFor="baseboardsExisting"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Existing Baseboards
                      </label>
                    </div>
                    <ErrorMessage
                      name="baseboards"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Type of New Floor */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="newFloorType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Type of New Floor
                    </label>
                    <Field
                      id="newFloorType"
                      name="newFloorType"
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Desired new floor type"
                    />
                    <ErrorMessage
                      name="newFloorType"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Brand/Product Field */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="brandProduct"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Brand/Product
                    </label>
                    <Field
                      id="brandProduct"
                      name="brandProduct"
                      type="text"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Desired brand/product"
                    />
                    <ErrorMessage
                      name="brandProduct"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Room Square Footage */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="roomSquareFootage"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Room Square Footage
                    </label>
                    <Field
                      id="roomSquareFootage"
                      name="roomSquareFootage"
                      type="number"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Enter room square footage"
                    />
                    <ErrorMessage
                      name="roomSquareFootage"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* Additional Details */}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="additionalDetails"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Additional Details
                    </label>
                    <Field
                      id="additionalDetails"
                      name="additionalDetails"
                      as="textarea"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Provide any additional details"
                      rows="4"
                    />
                    <ErrorMessage
                      name="additionalDetails"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="text-center">
                  {submitMessage && (
                    <div
                      className={isError ? "text-red-500" : "text-green-500"}
                    >
                      {submitMessage}
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <div className="mt-6 flex items-center justify-start gap-x-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default ContactPage;
