import React from "react";
import { Helmet } from 'react-helmet';
import {
  BiLeaf,
  BiBuildingHouse,
  BiBrush,
  BiDollarCircle,
  BiStar,
} from "react-icons/bi";

const AboutUsPage = () => {
  return (
    <div className="container mx-auto px-4 my-8">
      <Helmet>
        <title>About Fusion Floor Finishings Edmonton - Our Story and Expertise</title>
        <meta name="description" content="Learn about Fusion Floor Finishings in Edmonton, a leader in quality flooring solutions. Our story, commitment to excellence, and range of services." />
        <meta property="og:title" content="About Fusion Floor Finishings Edmonton" />
        <meta property="og:description" content="Discover the story behind Fusion Floor Finishings in Edmonton and our commitment to providing top-tier flooring services." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/Logo.jpeg" />
        <meta property="og:url" content="http://fusionfloorfinishings.ca/about-us" />
        <meta name="geo.region" content="CA-AB" />
        <meta name="geo.placename" content="Edmonton" />
        <link rel='canonical' href='http://fusionfloorfinishings.ca/about-us' />
      </Helmet>
      
      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-96 mb-10"
        style={{ backgroundImage: "url('/images/AboutUsHero.webp')" }}
      >
        <div className="flex flex-col justify-center items-center h-full bg-black bg-opacity-60 text-white">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
            About Fusion Floor Finishings
          </h1>
          <p className="text-xl text-center mb-6">
          Welcome to Fusion, where flooring is not just a necessity – it's an art
        form.
          </p>
          <a
            href="/about-us"
            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded text-white font-bold"
          >
            Learn More About Us
          </a>
        </div>
      </section>

      <div className="grid md:grid-cols-2 gap-4">
        {/* Nature's Aesthetic, Enhanced */}
        <div className="flex items-start mb-4">
          <BiLeaf className="text-4xl text-green-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">
              Nature's Aesthetic, Enhanced
            </h2>
            <p>
              Fusion's vinyl flooring captures the essence of natural materials
              like hardwood, stone, and tile but with added durability and
              versatility. Our designs mirror nature's beauty while offering the
              resilience required for daily life.
            </p>
          </div>
        </div>

        {/* Versatility Redefined */}
        <div className="flex items-start mb-4">
          <BiBuildingHouse className="text-4xl text-blue-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Versatility Redefined</h2>
            <p>
              With Fusion, discover a world of possibilities. Our vinyl flooring
              embodies the look and feel of natural materials without their
              limitations, offering a diverse range of styles and textures.
            </p>
          </div>
        </div>

        {/* Natural Look, Effortless Maintenance */}
        <div className="flex items-start mb-4">
          <BiBrush className="text-4xl text-yellow-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">
              Natural Look, Effortless Maintenance
            </h2>
            <p>
              Enjoy the stunning appearance of natural materials with
              easy-to-clean solutions, allowing you to relish beauty without the
              stress of extensive care routines.
            </p>
          </div>
        </div>

        {/* Accessible Luxury */}
        <div className="flex items-start mb-4">
          <BiDollarCircle className="text-4xl text-purple-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Accessible Luxury</h2>
            <p>
              Fusion believes in accessible luxury. Our vinyl flooring provides
              an affordable avenue to sophisticated elegance.
            </p>
          </div>
        </div>

        {/* Quality Craftsmanship, Every Time */}
        <div className="flex items-start mb-4">
          <BiStar className="text-4xl text-red-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">
              Quality Craftsmanship, Every Time
            </h2>
            <p>
              Our handpicked materials and expert craftsmanship ensure each
              installation reflects our commitment to excellence.
            </p>
          </div>
        </div>

        {/* Artisans of Flooring */}
        <div className="flex items-start mb-4">
          <BiBrush className="text-4xl text-green-600 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Artisans of Flooring</h2>
            <p>
              Our team comprises dedicated artisans passionate about their
              craft, bringing meticulous attention to detail from consultation
              to installation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
