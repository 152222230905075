// src/components/Footer.js
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4 bottom-0">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div>
            <p className="text-sm">
              © {new Date().getFullYear()} Fusion Floor Finishings. All rights
              reserved.
            </p>
          </div>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium sm:mt-0">
            <li>
              <a href="/about-us" className="hover:underline me-4 md:me-6">
                About Us
              </a>
            </li>
            <li>
              <a href="/services" className="hover:underline me-4 md:me-6">
                Services
              </a>
            </li>
            <li>
              <a href="/gallery" className="hover:underline me-4 md:me-6">
                Gallery
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:underline me-4 md:me-6">
                Contact
              </a>
            </li>
            <li>
              <a href="mailto:info@fusionfloorfinishings.ca" className="hover:underline flex items-center me-4 md:me-6">
                <AiOutlineMail className="mr-2"/> Email
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61554940944642" target="_blank" rel="noopener noreferrer" className="hover:underline flex items-center mr-2">
                <FaFacebook className="mr-2"/> Facebook
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
