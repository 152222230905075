import React from "react";
import { Helmet } from "react-helmet";

const GalleryPage = () => {
  // Array of image URLs for demonstration; replace with your actual image data
  const images = [
    "/images/Gallery/IMG_6038.webp",
    "/images/Gallery/IMG_6045.webp",
    "/images/Gallery/IMG_4402.webp",
    "/images/Gallery/IMG_5484.webp",
    "/images/Gallery/IMG_5483.webp",
    "/images/Gallery/IMG_5410.webp",
    "/images/Gallery/IMG_5272.webp",
    "/images/Gallery/IMG_5205.webp",
    "/images/Gallery/IMG_5199.webp",
    "/images/Gallery/IMG_5193.webp",
    "/images/Gallery/IMG_3994.webp",
  ];

  return (
    <div className="container mx-auto px-4 my-8">
      <Helmet>
        <title>Explore Our Flooring Project Gallery | Fusion Floor Finishings</title>
        <meta name="description" content="Discover Fusion Floor Finishings' gallery showcasing our high-quality flooring projects. Explore various designs and inspirations for your space." />
        <meta property="og:title" content="Fusion Floor Finishings Gallery" />
        <meta property="og:description" content="A visual journey through our diverse and expert flooring installations." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/Logo.jpeg" />
        <meta property="og:url" content="http://fusionfloorfinishings.ca/gallery" />
        <meta name="geo.region" content="CA-AB" />
        <meta name="geo.placename" content="Edmonton" />
        <link rel='canonical' href='http://fusionfloorfinishings.ca/gallery' />
      </Helmet>
      <h1 className="text-4xl md:text-5xl font-bold text-center mb-6 gray-800">Gallery</h1>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {images.map((image, index) => (
          <div key={index} className="overflow-hidden rounded-lg shadow-lg">
            <img
              src={image}
              alt={`Gallery item ${index + 1}`}
              className="h-full w-full max-w-full rounded-lg object-cover object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryPage;
