import React, { useState } from "react";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [showMoreEVP, setShowMoreEVP] = useState(false);
  const [showMoreVPT, setShowMoreVPT] = useState(false);
  const [showMoreCarpet, setShowMoreCarpet] = useState(false);
  const [showMoreVCT, setShowMoreVCT] = useState(false);

  return (
    <div className="container mx-auto px-4">
      <Helmet>
        <title>Fusion Floor Finishings Edmonton - Expert Flooring Solutions</title>
        <meta 
          name="description" 
          content="Fusion Floor Finishings offers top-quality flooring solutions in Edmonton. From vinyl planks to luxurious carpet tiles, find the perfect flooring for your space." 
        />
        <meta property="og:title" content="Fusion Floor Finishings Edmonton" />
        <meta property="og:description" content="Leading provider of professional flooring installations in Edmonton. Explore our wide range of high-quality flooring options tailored for your home or business." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/Logo.jpeg" />
        <meta property="og:url" content="http://fusionfloorfinishings.ca" />
        <meta name="geo.region" content="CA-AB" />
        <meta name="geo.placename" content="Edmonton" />
        <meta name="keywords" content="Flooring Edmonton, Vinyl Planks, Carpet Tiles, Floor Installation, VCT Flooring, Edmonton Flooring Solutions" />
        <link rel='canonical' href='http://fusionfloorfinishings.ca' />
      </Helmet>

      {/* Hero Section */}
      <section
        className="hero bg-cover bg-center h-96"
        style={{ backgroundImage: "url('/images/HeroBanner.webp')" }}
      >
        <div className="flex flex-col justify-center items-center h-full bg-black bg-opacity-60 text-white">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
            Fusion Floor Finishings
          </h1>
          <p className="text-xl text-center mb-6">
            Transforming Spaces with Exceptional Flooring Installations
          </p>
          <a
            href="/about-us"
            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded text-white font-bold"
          >
            Learn More About Us
          </a>
        </div>
      </section>

      {/* Flooring Options Section */}
      <section className="flooring-options my-8">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-6 gray-800">
          Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-1 gap-y-6">
          {/* Engineered Vinyl Planks & Tiles Card */}
          <div className="max-w-md mx-auto bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden">
            {/* ... Image for EVP&T ... */}
            <img
              src="/images/Engineering.webp"
              alt="Engineered Vinyl Planks & Tiles"
              className="w-full h-48 object-cover"
            />
            <div className="p-5">
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Engineered Vinyl Planks & Tiles
              </h3>
              <p className="text-gray-700">
                Experience the allure of natural materials paired with
                exceptional resilience in our engineered vinyl planks.
                {showMoreEVP &&
                  " These floating floors offer hassle-free maintenance, quick updates, and diverse design choices for style-conscious homeowners."}
              </p>
              <button
                onClick={() => setShowMoreEVP(!showMoreEVP)}
                className="text-blue-500 hover:text-blue-700 mt-3"
              >
                {showMoreEVP ? "Less" : "More"}
              </button>
            </div>
          </div>

          {/* Vinyl Planks & Tiles Card */}
          <div className="max-w-md mx-auto bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden">
            {/* ... Image for EVP&T ... */}
            <img
              src="/images/Vinyl.webp"
              alt="Vinyl Planks & Tiles"
              className="w-full h-48 object-cover"
            />
            <div className="p-5">
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Vinyl Planks & Tiles
              </h3>
              <p className="text-gray-700">
                Discover the enduring appeal of our glue-down vinyl planks,
                ideal for high-traffic areas.
                {showMoreVPT &&
                  " These planks promise exceptional durability, moisture resistance, and classic style for long-term, low-maintenance flooring solutions."}
              </p>
              <button
                onClick={() => setShowMoreVPT(!showMoreVPT)}
                className="text-blue-500 hover:text-blue-700 mt-3"
              >
                {showMoreVPT ? "Less" : "More"}
              </button>
            </div>
          </div>

          {/* Carpet Tiles Card */}
          <div className="max-w-md mx-auto bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden">
            {/* ... Image for EVP&T ... */}
            <img
              src="/images/Carpet.webp"
              alt="Carpet Tiles"
              className="w-full h-48 object-cover"
            />
            <div className="p-5">
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Carpet Tiles
              </h3>
              <p className="text-gray-700">
                Discover the versatility and comfort of our carpet tiles.
                Perfect for any space, these tiles offer flexibility in design
                and functionality.
                {showMoreCarpet &&
                  " Enjoy the plush feel and sound absorption benefits of carpet while relishing the ease of maintenance. Our service encompasses more than just installation; we ensure a flawless finish, including expertly placed transitions and meticulously installed base finishings to complement your new carpet tiles seamlessly."}
              </p>
              <button
                onClick={() => setShowMoreCarpet(!showMoreCarpet)}
                className="text-blue-500 hover:text-blue-700 mt-3"
              >
                {showMoreCarpet ? "Less" : "More"}
              </button>
            </div>
          </div>

          {/* VCT (Vinyl Composition Tile) Card */}
          <div className="max-w-md mx-auto bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden">
            {/* ... Image for EVP&T ... */}
            <img
              src="/images/VCT.webp"
              alt="Vinyl Composition Tile"
              className="w-full h-48 object-cover"
            />
            <div className="p-5">
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                VCT (Vinyl Composition Tile)
              </h3>
              <p className="text-gray-700">
                For spaces demanding durability and timeless appeal, our VCT
                flooring is the go-to solution. These resilient tiles offer
                exceptional performance in high-traffic areas.
                {showMoreVCT &&
                  " Benefit from easy cleaning and maintenance while reveling in a wide range of design options. Beyond the installation, our service encompasses the complete package, ensuring all transitions and baseboards are seamlessly integrated, providing a professional and finished look to your VCT flooring."}
              </p>
              <button
                onClick={() => setShowMoreVCT(!showMoreVCT)}
                className="text-blue-500 hover:text-blue-700 mt-3"
              >
                {showMoreVCT ? "Less" : "More"}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Prompt Section */}
      <section
        className="hero bg-cover bg-center py-16 mb-8"
        style={{ backgroundImage: "url('images/ContactUsBanner.webp')" }}
      >
        <div className="flex flex-col justify-center items-center h-full p-10 bg-black bg-opacity-60 text-white">
          <h2 className="text-3xl font-bold text-grey mb-6">
            Ready to Transform Your Space?
          </h2>
          <a
            href="/contact" // Update the href to your Contact Us page route
            className="px-6 py-3 bg-blue-600 hover:bg-blue-800 rounded text-white font-bold"
          >
            Contact Us Now
          </a>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
